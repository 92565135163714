import React from 'react';
import NICALocatorCore from '@devsontap/nica-locator-core';

import Sponsors from './components/sponsors';
import TopNav from "./components/topNav";
import WelcomeHeader from "./components/welcomeHeader";

import logo from './img/iaf-logo-small-ko.png';
import logoWhite from './img/ic-nica-logo-large-knockout.svg';
import loginBackgroundImage from './img/login-bg.jpg';
import distanceIcon from './img/ic-distance-large.svg';
import hoursIcon from './img/ic-hours-large.svg';

import './App.scss';

const App = () => {
    return (
        <NICALocatorCore
            themeOptions={{
                primaryColor: "#1C2443",
                secondaryColor: "#3C58A6",
                secondaryContrastText: "#fff",
                headlineFontFamily: "Libre Baskerville",
                bodyFontFamily: "Roboto",
                overrides: {
                    MuiButton: {
                        text: {
                            color: "#aab4ba"
                        },
                        textSecondary: {
                            textTransform: "none"
                        },
                        outlined: {
                            border: "white 2px solid",
                            color: "white"
                        }
                    },
                    MuiDialogContent: {
                        root: {
                            fontSize: "22px",
                            fontWeight: 300,
                            color: "#979a9f"
                        }
                    },
                    MuiSelect: {
                        root: {
                            // color: "white",
                            fontSize: "15px",
                        }
                    },
                }
            }}
            images={{
                logo,
                logoWhite,
                loginBackgroundImage,
                distanceIcon,
                hoursIcon
            }}
            components={{
                // FiltersComponent: Filters,
                FooterComponent: Sponsors,
                TopNavComponent: TopNav,
                WelcomeHeaderComponent: WelcomeHeader
            }}
            flags={{
                // hideFilters: true,
                hideGoToAdmin: true,
                showCovidVerified: false
            }}
            strings={{
                welcomeMessage: (
                    <>
                        <div>
                            Infusion sites displayed in this tool have been added by either the National Infusion Center
                            Association (NICA) team through research or by the site itself. NICA is a nonprofit trade
                            association and does not own or operate any infusion sites.
                        </div>
                        <p>
                            Infusion sites are responsible for adding their center to the locator or claiming an existing center and
                            keeping all information up to date. NICA is not liable for the validity of contact information in a site’s
                            profile.
                        </p>
                        <p>
                            The locator serves as a free tool to connect patients with the most accessible site of care in their area
                            and for providers to find referral sites. Patients should contact the center directly with all medication,
                            referral, facility, and otherwise site-specific inquiries.
                        </p>
                    </>
                )
            }}>
        </NICALocatorCore>
    );
};

export default App;
