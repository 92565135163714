import React from 'react';

import pdf from '../../img/One-Pager-CMab.pdf';

const FAQs = [
    {
        question: "If a COVID-19 monoclonal antibody (CmAb) infusion location is listed in the locator, is it guaranteed to have medication at all times?",
        answer: (
            <div>
                No. The COVID-19 landscape is rapidly evolving and it is your responsibility to call them to confirm they
                are still actively administering the CmAb therapeutics and scheduling treatment appointments for COVID-19 patients.<br/><br/>
                The NICA team receives and verifies data from the three current manufacturers of CmAb therapies on a weekly basis.
            </div>
        )
    },
    {
        question: "I know of a site in my community that offers CmAb therapeutics to the public, but it’s not listed as an option in the locator tool! How can I let NICA know that this site needs to be added?",
        answer: (
            <div>
                Some sites may not be in the locator due to being secondary distribution sites or “pop-up” facilities.
                If you know of a site that is not currently listed in the NICA CmAb locator, please contact us at&nbsp;
                <a href="mailto:covid19@infusioncenter.org">covid19@infusioncenter.org</a>.
            </div>
        )
    },
    {
        question: "How can I confirm if a site in the locator has available CmAb therapy and schedule an appointment for treatment?",
        answer: (
            <div>
                Within each infusion site profile, there will be a phone number that you can use to call the site. Once
                you connect with the location over the phone, you may ask them if they are actively scheduling appointments
                for COVID-19 treatment.
            </div>
        )
    },
    {
        question: "I am trying to access the NICA CmAb locator from outside the United States. Why can’t I use the tool?",
        answer: (
            <div>
                The National Infusion Center Association’s scope is limited to the continental United States of America
                and U.S. Territories. The tool is not available for international users. If you believe you are being
                restricted from the tool in error, please contact <a href="mailto:covid19@infusioncenter.org">covid19@infusioncenter.org</a>.
            </div>
        )
    },
    {
        question: "There is incorrect information in a site’s profile, or the site that I called is no longer offering COVID-19 treatment. How can I notify NICA that information needs to be updated? ",
        answer: (
            <div>
                The COVID-19 landscape is rapidly changing every day. As a result, some site information such as phone
                numbers or operating hours may be outdated depending on when you search for treatment. You may use our
                feedback form (located within each site’s profile: SEE INFO THAT NEEDS AN UPDATE?) to notify NICA. Please
                note, NICA does not respond to these feedback requests.
            </div>
        )
    },
    {
        question: "Can NICA schedule my infusion appointment?",
        answer: (
            <div>
                No. NICA is a nonprofit organization. We do not own or operate any infusion centers, nor are we healthcare
                providers. Please contact the locations in the tool if you have any personal health questions or appointment questions.
            </div>
        )
    },
    {
        question: "Are monoclonal antibodies right for me?",
        answer: (
            <div>
                Please review <a href={pdf} target="_blank" rel="noopener noreferrer">this attachment</a> as a general guide. You will need to contact a healthcare provider to
                confirm that you are eligible to receive an infusion.
            </div>
        )
    },
    {
        question: "Can I schedule an appointment if I do not have health insurance?",
        answer: (
            <div>
                Please review this document to learn more about frequently asked questions about the CmAb therapies themselves<br/><br/>
                You may also visit <a href="https://combatcovid.hhs.gov/" target="_blank" rel="noopener noreferrer">here</a> to learn more.
            </div>
        )
    },
    {
        question: "What is the difference between “Claimed” vs. “Verified” listing?",
        answer: (
            <div>
                A “Claimed” listing is one that an administrator from the center has taken ownership of at one point in time.
                A “Verified” listing is one for which a representative from one of the manufacturers has called that center at
                one point in time and verified monoclonal antibodies are available at that center. Regardless of whether the
                listing is “Claimed” or “Verified,” please directly call to personally confirm that they have the antibody
                therapy available as the COVID-19 landscape continues to rapidly evolve, and this includes those centers
                that administer the therapies.
            </div>
        )
    },
    {
        question: "How often is the site updated?",
        answer: (
            <div>
                The NICA team is currently adding new locations to the tool every week.
            </div>
        )
    }
];

export default FAQs;