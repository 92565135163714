import React from 'react';

import './index.css';

const WelcomeHeader = () => {
    return (
        <div className="-mb-5">
            {/*<div className="locator-form-header-title headlineFont sm:hidden">*/}
            {/*    Infusion Center Locator*/}
            {/*</div>*/}
        </div>
    );
};

export default WelcomeHeader;