import React from 'react';

import nica from '../../img/nica-logo.svg';

import './index.css';

const Sponsors = ({ label }) => {
    return (
        <div className="flex sponsors-container">
            <div className="sponsors-label bodyFont">
                {label}
            </div>
            <div className="flex sponsors-logos ml-6 sm:ml-0">
                <div className="">
                    <a href="https://infusioncenter.org" target="_blank" rel="noopener noreferrer">
                        <img src={nica} alt="NICA" className="sponsor-img" />
                    </a>
                </div>
            </div>
        </div>
    )
};

Sponsors.defaultProps = {
    label: "IAF Locator powered by:"
};

export default Sponsors;